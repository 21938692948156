/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */
.CartRewardsSection {
    margin-block: 22px;
    padding-block: 24px;
    padding-inline: 16px;
    background: #ffffff;
    overflow: hidden;
    .ProductCard {
        max-width: 283px;
        margin: auto;
        @include mobile {
            max-width: 172px;
        }
    }
    @include  mobile {
        padding-inline: 3px;
        @include shadow-card;
    }
    &-Header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block-end: 20px;
        font-weight: bold;
        @include mobile {
            padding-inline: 14px;
        }
        &Text{
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            @include mobile {
                font-size: 14px;
            }
        }
        &TimeLeft{
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #E03065;
            display: flex;
            align-items: center;
            gap: 10px;
            @include mobile {
                font-size: 13px;
            }
        }
    }

    .slick {
        &-slide{
            width: 295px;
            @include mobile {
                width: 280px;
            }
            > div {
                //display: flex;
                //align-items: center;
            }
        }
        &-arrow {
            width: 64px;
            height: 64px;
            z-index: 4;
            @include mobile {
                width: 42px;
                height: 42px;
            }
        }
        &-prev {
            left: 2%;
            opacity: 0.5;
            background: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                height: auto;
                transform: rotate(180deg);
            }
            &::before {
                display: none;
            }
        }
        &-next {
            right: 2%;
            opacity: 0.5;
            background: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                height: auto;
            }
            &::before {
                display: none;
            }
        }
    }
    &-Card{
        // .splide__arrows{
        //     position: absolute;
        //     width: 100%;
        //     top: 50%;
        // }
        .slick-list{
            @include mobile {
                padding:0 20px 0 0 !important;
            }
        }
    }
    .ProductCard-PriceWrapper{
        padding-top: 0;
    }
    .ProductCard-Name {
        font-size: 14px;
    }
    .ProductCard-PriceWrapper span{
        font-size: 15px;
    }
    .ProductCard-AddToCart{
        border-radius: 8px;
        padding: 6px 10px;
    }
    .ProductCard-Content_namewrapper{
        padding: 5px;
        .slick-slide {
            float: right;
            padding-inline-end: 16px;
        }
    }
    &-Product {
        background: #FFFFFF;
        border: 1px solid #E84179;
        border-radius: 8px;
        display: block;
        &Discount {
            padding: 4px 0px;
            background: #E84179;
            border: 1px solid #E84179;
            border-radius: 8px 8px 0px 0px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #FFFFFF;
            text-align: center;
            text-transform: capitalize;
            &_isHidden {
                // color: rgba(255, 255, 255, 0);
                opacity: 0;
            }
        }
        &Image{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            img {
                width: 120px;
                height: 160px;
                object-fit: contain;
            }
        }
        &Name{
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #000000;
            padding-inline: 8px;
            min-height: 32px;
            margin-block-end: 16px;
            text-overflow:ellipsis;
            overflow:hidden;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
        }
        &Price {
            min-height: 54px;
            text-align: center;
            padding-inline: 2px;
            .ProductPrice {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                &-HighPrice {
                    color: inherit;
                    opacity: 0.8;
                }
                del {
                    font-size: 11px;
                }
            }
            .ProductPage-DiscountPerc{
                display: none;
            }
        }
    }

    &-AddToCart{
        .Button{
            background: #E03065;
            color: var(--color-black);
            border: none;
            font-size: 13px;
            height: 38px;
            width: 100%;
            padding: 0;
            text-align: center;
            line-height: 38px;
            @include mobile {
                min-width: 100px;
            }
            &:hover {
                background: #E03065;
            }
            .CartIcon{
                padding-bottom: 0;
                // fill: #E03065;
            }
        }
    }

    &_brown {
        border: 1px solid #E9AD88;
        .CartRewardsSection {
            &-AddToCart{
                .Button{
                    background: #E9AD88;
                }
            }
            &-Product {
                border: 1px solid #E9AD88;
                &Discount {
                    background: #E9AD88;
                    border: 1px solid #E9AD88;
                }
            }
        }
    }

    &_green {
        border: 1px solid #68AAAA;
        .CartRewardsSection {
            &-AddToCart{
                .Button{
                    background: #68AAAA;
                }
            }
            &-Product {
                border: 1px solid #68AAAA;
                &Discount {
                    background: #68AAAA;
                    border: 1px solid #68AAAA;
                }
            }
        }
    }

    .ProductCard .ProductCard-AddToCart {
        font-size: 13px;
    }
}