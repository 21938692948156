/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --cart-page-divider-background: var(--secondary-base-color);
    --cart-page-promo-background: #fff;
    --cart-page-table-head-background: var(--secondary-base-color);
}

.CartPage {
    margin-block-end: var(--footer-total-height);

    @include mobile {
        --footer-totals-height: 73px;

        padding-block-end: var(--footer-totals-height);
        padding-inline: 16px;
    }

    &-Wrapper {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 360px;
            grid-column-gap: 48px;
        }

        @include mobile {
            padding: 0;
        }
    }

    &-Empty {
        @include mobile {
            padding: 14px;
            border-block-end: 1px solid var(--expandable-content-divider-color);
            text-align: center;
        }
    }

    &-Summary {
        background: var(--color-white);

        @include mobile {
            inset-inline-start: 0;
            width: 100%;
        }
    }

    &-CheckoutButtons {
        padding: 12px 0;

        @include mobile {
            padding: 14px;
        }
    }

    &-OutOfStockProductsWarning {
        padding: 10px;
        display: flex;
        justify-content: center;
        background-color: var(--primary-error-color, red);
        font-size: 14px;
        font-weight: 600;
        color: var(--color-white);
        inset-inline-start: -16px;
        width: calc(100% + 32px);
    }

    &-CheckoutButton {
        width: 100%;
        text-align: center;

        @include desktop {
            margin: 10px 0;
        }
    }

    &-Promo,
    &-Total {
        min-height: 48px;

        @include mobile {
            min-height: 0;
        }
    }

    &-Promo {
        order: 1;
        background: var(--cart-page-promo-background);

        @include desktop {
            margin-block-start: 24px;
        }

        &Block {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--cart-page-divider-background);
            padding: 24px 12px;
            border-radius: var(--input-border-radius);

            @include mobile {
                margin-block: 12px;
                padding: 14px;
            }
        }

        &Image {
            width: 46px;
            margin-inline-end: 6px;

            @include mobile {
                width: 36px;
                margin-inline-end: 7px;
            }
        }

        strong {
            margin: 0 5px;
        }
    }

    &-Total {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        align-items: center;
        font-weight: bold;
        padding: 12px 0;

        @include mobile {
            padding-block-start: 14px;
            padding-block-end: 0;
            padding-inline: 14px;
        }

        dd {
            text-align: end;

            span {
                display: block;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    &-TableHead {
        display: grid;
        grid-template-columns: 3fr 2fr 1fr;
        background-color: var(--cart-page-table-head-background);
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 600;
        padding: 14px 16px;
        margin-block-end: 0;
        border-radius: var(--input-border-radius);

        @include mobile {
            display: none;
        }

        span {
            text-align: start;

            &:last-of-type {
                text-align: end;
            }
        }
    }

    &-Discount {
        border-block-start: 0;

        @include desktop {
            margin-block-start: 24px;
            &Content{
                .Form {
                    width: 100%;
                    .CartCoupon-Title, .CartCoupon-Input {
                        width: 100%;
                    }
                    .CartCoupon-Button {
                        width: auto;
                    }
                }
            }
        }

        @include mobile {
            margin-block-start: 16px;

            &Heading{
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                margin-block-end: 8px;
            }
            &Content{
                background: #FFFFFF;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                padding: 16px;
            }
        }

        .CartCoupon-Input {
            display: block;
            input {
                width: 100%;
                margin-bottom: 8px;
            }
        }
        .CartCoupon-Button {
            width: 100%;
            margin-left: 0;
        }
    }

    &-Floating {
        @include desktop {
            display: flex;
            flex-direction: column;
            margin-block-start: 86px;
        }

        @include tablet {
            margin-block-start: 24px;
        }
        @include mobile {
            padding: 16px;
            background: var(--color-white);
            @include shadow-card;
        }
    }

    &-Heading {
        margin-block: 24px;

        @include mobile {
            display: none;
        }
    }

    &-ExpandableContentContent {
        margin-block-start: 0;
    }

    .ProductLinks-Wrapper {
        padding-inline: 0;

        @include desktop {
            padding-inline: 32px;
        }
    }

    .ProductLinks-List {
        grid-column-gap: 16px;
        z-index: 10;

        .ProductCard-ProductActions {
            display: flex;
        }

        .AddToCart {
            width: 100%;
            margin-inline-end: 0;
        }
    }

    &-Login {
        @include shadow-card;
        background: none;
        margin-block: 16px;
        background: #ffffff;
        &Link{
            width: 100%;
            display: block;
            text-align: center;
            padding: 10px;
        }
    }

    &-CartRewards {
        .ContentWrapper {
            @include mobile {
                padding-inline: 0;
            }
        }
    }

    &-ExtraDiscount{
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 21px;
        span{
            font-weight: 600;
            color: var(--color-brown);
        }
    }
}
