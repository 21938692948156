/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */
.CartOverlay {
    &-Tax {
        font-size: 16px;
    }
    @include desktop {
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        position: fixed !important;
        height: 100% !important;
        .CartOverlay-ContentWrapper {
            height: calc(100vh - 46px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            &-Overlay {
                width: 100vw;
                height: 100vh;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #00000069;
                z-index: -100;
            }
        }
        .CartOverlay-Items {
            max-height: calc(100vh - 160px);
            padding-block: 13px;
            display: flex;
            flex-direction: column;
            gap: 13px;
            justify-content: flex-start;
            &::-webkit-scrollbar {
                width: 0;
            }
        }
        .CartOverlay-Header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            font-size: 17px;
            button {
                font-size: 22px;
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    &-CouponForm{
        padding: 10px;
    }
}
